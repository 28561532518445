<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_pannel">

                    <!-- contents -->

                    <div class="dev_project_view pu_row">
                        <div class="pu_pannel">

                            <div class="pu_view_img">
                                <div class="pu_view_img_title"></div>
                                <div class="pu_view_img_title_sub"></div>
                                <div class="pu_view_img_main"></div>
                            </div>

                        </div>
                    </div>

                    <!-- //contents -->

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import

    // export
    export default {

        data () {
            return {

                gubun_page: "VIEW"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

            }
        }

        , methods: {

            // 진입점
            init () {

                // 프로젝트뷰 세팅
                this.setProjectView();

                this.getViewData();

            }

            // 데이터 조회
            , getViewData () {

                var data = {};
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/projects/view", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.list_1 = data.LIST_1; // 리스트

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {



                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 프로젝트뷰 세팅
            , setProjectView () {

                var view = $(".dev_project_view");
                if (view.length > 0) {

                    var qs = this.getUrlParams();
                    var cont = $(".pu_view_img");
                    cont.find(".pu_view_img_title").text(arrProjectInfo[qs.no].title);
                    cont.find(".pu_view_img_title_sub").text(arrProjectInfo[qs.no].cont);
                    cont.find(".pu_view_img_main").css({ "background-image": "url(" + arrProjectInfo[qs.no].img + ")" });

                }

                // 이미지 사이즈 변경
                setProjectImgSize();
            }

            , getUrlParams () {

                var params = {};
                window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
                    function (str, key, value) {
                        params[key] = value;
                    }
                );
                return params;

            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>