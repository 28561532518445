<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_pannel">

                    <!-- contents -->

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>Step1. 기획설계 및 컨설팅</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        바른 건축설계 문화를 위해 무료 규모검토는 진행하지 않습니다.
                                    </p>

                                    <p>
                                        일정 및 비용 등이 궁금하면 메일주시면 답변을 드리겠습니다.
                                    </p>

                                    <p>
                                        yonghanoffice@naver.com
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>Step2. 건축계획 및 인허가</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        허가도서까지 필요한 경우에는 허가도서까지 진행합니다.
                                    </p>

                                    <p>
                                        각종 심의 통과까지만 진행하고, 허가는 별도로 진행하셔도 좋습니다.
                                    </p>

                                    <p>
                                        yonghanoffice@naver.com
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="pu_row">
                        <div class="pu_pannel">

                            <div class="pu_writing">
                                <div class="pu_writing_title">
                                    <strong>Step3. 실시설계 및 준공</strong>
                                </div>

                                <div class="pu_writing_cont">
                                    <p>
                                        기획부터 인허가, 착공신고 및 사용승인까지 사업의 전체 과정을 함께합니다.
                                    </p>

                                    <p>
                                        현장과 소통이 잘 되도록 유연하게 대처하고자 합니다.
                                    </p>

                                    <p>
                                        yonghanoffice@naver.com
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- //contents -->

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>

    // export
    export default {

        data() {
            return {

                gubun_page: "LIST"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

            }
        }

        , methods: {

            // 진입점
            init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var data = {};
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/contact/list", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.list_1 = data.LIST_1; // 리스트

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {

                            // dimm 숨기기
                            HideWhiteDimm();

                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>