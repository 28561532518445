<template>

    <!--================ Title Menu Area =================-->

    <div></div>

    <!--================ //Title Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getPathCur: "getPathCur"
                , getUserLoginYn: "getUserLoginYn"
                , getUserNo: "getUserNo"
                , getUserUrlCode: "getUserUrlCode"
                , getArrUserMenu_d1: "getArrUserMenu_d1"
            }),

        }

        ,data() {
            return {

                THIS_NAME: "INC TITLE",

            };
        },

        methods: {

            ...mapActions({
                setPathCur: "setPathCur"
                , setUserLoginYn: "setUserLoginYn"
                , setUserNo: "setUserNo"
                , setUserUrlCode: "setUserUrlCode"
                , setArrUserMenu_d1: "setArrUserMenu_d1"
            })

            // 진입점
            , init() {

            },

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
