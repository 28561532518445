<template>

    <div class="pu_login_wrapper pu_row">

        <div class="pu_login_body">

            <form id="regiForm" onsubmit="return false;">

                <div class="col-xs-12 pu_box_header">
                    <h3 class="box-title">
                        <img src="/common/img/item/logo/logo_ori.jpg" alt="로고" style="width: 230px;" />
                    </h3>
                </div>

                <div class="col-xs-12 pu_box_body">
                    <div class="pu_col_lg_12">
                        <label class="col-xs-2">ID</label>
                        <input type="text" name="id" class="col-xs-9" v-model="form.id" autocomplete="off" style="border: solid 1px #a6a6a6; border-radius: 5px; color: #a6a6a6; text-align:center">
                    </div>

                    <div class="col-xs-12" style="margin-top:10px">
                        <label class="col-xs-2">PW</label>
                        <input type="password" name="pw" class="col-xs-9" v-model="form.pw" autocomplete="off" style="border: solid 1px #a6a6a6; border-radius: 5px; color: #a6a6a6; text-align:center">
                    </div>
                </div>

                <div class="col-xs-12 pu_box_footer" style="margin-top:10px">
                    <a class="dev_btn_submit pu_btn_login btn btn-primary" href="javascript:;"
                       style="width: 240px; height: 45px; line-height: 28px; font-size: 25px;background-color: #cccccc; border-color: #cccccc;">
                        Login
                    </a>
                </div>
            </form>

        </div>

        <div class="pu_login_footer">
            &copy; Copyright <a href="http://hyper-nomad.com">HyperNomad</a>. All Rights Reserved
        </div>

    </div>

</template>

<script>
    // import

    // export
    export default {

        data() {
            return {

                gubun_page: "REGIFORM"

                , form: {
                    id: ""   // 아이디
                    , pw: "" // 비밀번호
                }

            }
        }

        , methods: {

            // 진입점
            init() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", function () { $(".dev_btn_submit").click(); });

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData() {

                // 유효성 검사
                {
                    if (this.$IsEmpty(this.form.id)) {
                        alert("아이디를 입력해주세요.");
                        $("[name='id']").focus();
                        return;
                    }

                    if (this.$IsEmpty(this.form.pw)) {
                        alert("비밀번호를 입력해주세요.");
                        $("[name='pw']").focus();
                        return;
                    }
                }

                // dimm 보이기
                this.$ShowDimmLogo();

                // 데이터 추가
                this.form.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/login/login", this.form, this.saveDataReturn);

            }

            // 저장 데이터
            , saveDataReturn(data, err) {

                if (data && data.RESULT_YN == "N") {

                    // dimm 숨기기
                    this.$HideDimmLogo();

                }

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>