<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_pannel">

                    <!-- contents -->

                    <div class="dev_project_list pu_row">
                        <div class="pu_pannel">

                            <ul class="pu_grid_ul">

                                <li id="dev_project_li" class="pu_grid_li col-md-3 col-sm-4 col-xs-6 col-xxs-12">
                                    <div class="pu_grid_item">
                                        <div class="pu_grid_img"></div>
                                        <div class="pu_grid_cont">
                                            <input type="hidden" name="no" />
                                            <div class="pu_grid_cont_title"></div>
                                            <div class="pu_grid_cont_cont"></div>
                                        </div>
                                    </div>
                                </li>

                            </ul>

                        </div>
                    </div>

                    <!-- //contents -->

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import

    // export
    export default {

        data() {
            return {

                gubun_page: "LIST"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

            }
        }

        , methods: {

            // 진입점
            init() {

                // 리스트 세팅
                this.setProjectList();

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var data = {};
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/projects/list", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.list_1 = data.LIST_1; // 리스트

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {



                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 프로젝트 세팅
            , setProjectList() {

                var list = $(".dev_project_list");
                if (list.length > 0) {

                    var li = $("#dev_project_li").clone();
                    $("#dev_project_li").remove();

                    for (var idx = 0; idx < arrProjectInfo.length; idx++) {

                        var li_clone = li.clone();
                        li_clone.find("[name='no']").val(idx);
                        li_clone.find(".pu_grid_img").css({ "background-image": "url(" + arrProjectInfo[idx].img_thum + ")" });
                        li_clone.find(".pu_grid_cont_title").text(arrProjectInfo[idx].title);
                        li_clone.find(".pu_grid_cont_cont").text(arrProjectInfo[idx].cont);

                        $(".pu_grid_ul").append(li_clone);

                        // 클릭 이벤트
                        li_clone.on("click", { no: idx }, (e) => {

                            var no = e.data.no;
                            this.$PageMove("./view?no=" + no);

                        });
                    }
                }

                // 이미지 사이즈 변경
                setProjectImgSize();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>