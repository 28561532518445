<template>

    <!--================ Top Menu Area =================-->

    <div class="dev_top pu_top">
        <div class="pu_row">
            <div class="pu_pannel">

                <div class="pu_wrapper_logo">
                    <a href="javascript:;" v-on:click="$PageMove('/')">
                        Yong Han Architecture
                        <span>Engineering & Consultants</span>
                    </a>
                </div>

                <div class="pu_nav">
                    <ul class="pu_menu_1d">

                        <li v-for="(d1, idx_d1) in getArrUserMenu_d1" v-bind:key="idx_d1">
                            <a v-bind:class="d1.ACTIVE" href="javascript:;" v-on:click="$PageMove(d1.URL)">{{ d1.TITLE }}</a>
                        </li>

                        <!--
                        <li>
                            <a href="https://blog.naver.com/yonghanoffice" target="_blank">Blog</a>
                        </li>
                        -->
                        <!--
                        <li v-if="user_login_yn === 'N'">
                            <a href="javascript:;" v-on:click="$PageMove('/user/login/login')">Login</a>
                        </li>
                        <li v-if="user_login_yn === 'Y'">
                            <a href="javascript:;" v-on:click="$PageMove('/user/login/logout')">Logout</a>
                        </li>
                        -->

                    </ul>

                    <ul class="pu_menu_2d">

                        <li v-for="(d2, idx_d2) in getArrUserMenu_d2" v-bind:key="idx_d2">
                            <a v-bind:class="d2.ACTIVE" href="javascript:;" v-on:click="$PageMove(d2.URL)">{{ d2.TITLE }}</a>
                        </li>

                    </ul>
                </div>

            </div>
        </div>
    </div>

    <!--================ //Top Menu Area =================-->

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getPathCur: "getPathCur"
                , getUserLoginYn: "getUserLoginYn"
                , getUserNo: "getUserNo"
                , getUserUrlCode: "getUserUrlCode"
                , getArrUserMenu_d1: "getArrUserMenu_d1"
                , getArrUserMenu_d2: "getArrUserMenu_d2"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC TOP"

            };
        },

        methods: {

            ...mapActions({
                setPathCur: "setPathCur"
                , setUserLoginYn: "setUserLoginYn"
                , setUserNo: "setUserNo"
                , setUserUrlCode: "setUserUrlCode"
                , setArrUserMenu_d1: "setArrUserMenu_d1"
                , setArrUserMenu_d2: "setArrUserMenu_d2"
            })

            // 진입점
            , init() {

                // MENU 1D
                this.getMenu1d();

            }

            // MENU 1D
            , getMenu1d() {

                // 데이터
                var data = {};
                data["url"] = this.getPathCur;

                // API 조회
                this.$SendPost("/vue_api/common/menu/user_1d", data, this.getMenu1dReturn);

            }

            // MENU 1D 리턴
            , getMenu1dReturn(data, err) {

                if (data) {

                    this.setUserLoginYn(data.USER_LOGIN_YN); // 로그인 여부
                    this.setUserNo(data.USER_NO);            // 로그인 번호
                    this.setArrUserMenu_d1(data.LIST_1);     // 1뎁스 메뉴

                }

                // MENU 2D
                this.getMenu2d();

            }

            // MENU 2D
            , getMenu2d() {

                // 데이터
                var data = {};
                data["url"] = this.getPathCur;

                // API 조회
                this.$SendPost("/vue_api/common/menu/user_2d", data, this.getMenu2dReturn);

            }

            // MENU 2D 리턴
            , getMenu2dReturn(data, err) {

                if (data) {

                    console.log(data.LIST_1);

                    this.setArrUserMenu_d2(data.LIST_1);     // 1뎁스 메뉴

                }
            }

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
