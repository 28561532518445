<template>

    <!--================ Head Menu Area =================-->

    <div></div>

    <!--================ //Head Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getPathCur: "getPathCur"
            }),

        },

        data() {
            return {

                THIS_NAME: "INC HEAD"

            };
        },

        methods: {

            ...mapActions({ setPathCur: "setPathCur" })

            // 진입점
            ,init() {

                // 현재 경로
                this.setPathCur(window.location.pathname);

            },
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
