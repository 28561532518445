<template>

    <!--================ Foot Menu Area =================-->

    <div class="pu_foot">
        <div class="pu_row">
            <div class="pu_pannel">
                <div class="pu_copyright">
                    © Copyright <a href="http://hyper-nomad.com">HyperNomad</a>. All Rights Reserved
                </div>
                <div class="pu_login">
                    <a href="javascript:;" v-if="getUserLoginYn == 'N'" v-on:click="$PageMove('/user/login/login')">Login</a>
                    <a href="javascript:;" v-if="getUserLoginYn == 'Y'" v-on:click="$PageMove('/user/login/logout')">Logout</a>
                </div>
            </div>
        </div>
    </div>

    <!--================ //Foot Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getPathCur: "getPathCur"
                , getUserLoginYn: "getUserLoginYn"
                , getUserNo: "getUserNo"
                , getUserUrlCode: "getUserUrlCode"
                , getArrUserMenu_d1: "getArrUserMenu_d1"
            }),

        },

        data() {
            return {

                THIS_NAME: "INC FOOT"

            };
        },

        methods: {

            ...mapActions({
                setPathCur: "setPathCur"
                , setUserLoginYn: "setUserLoginYn"
                , setUserNo: "setUserNo"
                , setUserUrlCode: "setUserUrlCode"
                , setArrUserMenu_d1: "setArrUserMenu_d1"
            })

            // 진입점
            , init() {

            }

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
        },

        mounted() {

            this.$PrintLog(this.THIS_NAME + " : mounted");
            this.init();

        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
