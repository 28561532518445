/* 

[[ readme ]]

- store.js 는 각 vue에서 사용되는 공통 데이터 및 함수를 정의 한다.
- actions > mutations > getters > state 로 접근한다.

도움 : https://any-ting.tistory.com/44

*/

// [[ 라이브러리 import ]]
import Vue from "vue"; // vue
import Vuex from "vuex"; // vuex

Vue.use(Vuex);

// [[ vuex 설정 ]]
export const store = new Vuex.Store({

    // 데이터
    state: {

        pathCur: ""            // 현재 접속경로
                               
        , userLoginYn: "N"     // 유저 로그인 여부
        , userNo: "-1"         // 유저 번호
                               
        , userUrlCode: ""      // 유저 메뉴 코드
        , arrUserMenu_d1: []   // 유저 1뎁스 메뉴
        , arrUserMenu_d2: []   // 유저 2뎁스 메뉴

    },

    // 게터
    getters: {

        // 현재 접속경로
        getPathCur: (state) => {
            return state.pathCur;
        },

        // 유저 로그인 여부
        getUserLoginYn: (state) => {
            return state.userLoginYn;
        },

        // 유저 번호
        getUserNo: (state) => {
            return state.userNo;
        },

        // 유저 메뉴 코드
        getUserUrlCode: (state) => {
            return state.userUrlCode;
        },

        // 유저 1뎁스 메뉴
        getArrUserMenu_d1: (state) => {
            return state.arrUserMenu_d1;
        },

        // 유저 2뎁스 메뉴
        getArrUserMenu_d2: (state) => {
            return state.arrUserMenu_d2;
        },

    },

    // 동기 메서드
    mutations: {

        // 현재 접속경로
        setPathCur: (state, param) => {
            state.pathCur = param;
        },

        // 유저 로그인 여부
        setUserLoginYn: (state, param) => {
            state.userLoginYn = param;
        },

        // 유저 번호
        setUserNo: (state, param) => {
            state.userNo = param;
        },

        // 유저 메뉴 코드
        setUserUrlCode: (state, param) => {
            state.userUrlCode = param;
        },

        // 유저 1뎁스 메뉴
        setArrUserMenu_d1: (state, param) => {
            state.arrUserMenu_d1 = param;
        },

        // 유저 2뎁스 메뉴
        setArrUserMenu_d2: (state, param) => {
            state.arrUserMenu_d2 = param;
        },

    },

    // 비동기 메서드 : api 에서 데이터 받아서 반영 > 동기 메서드
    actions: {

        // 현재 접속경로
        setPathCur: ({ commit, state }, param) => {
            commit("setPathCur", param);
        },

        // 유저 로그인 여부
        setUserLoginYn: ({ commit, state }, param) => {
            commit("setUserLoginYn", param);
        },

        // 유저 번호
        setUserNo: ({ commit, state }, param) => {
            commit("setUserNo", param);
        },

        // 유저 메뉴 코드
        setUserUrlCode: ({ commit, state }, param) => {
            commit("setUserUrlCode", param);
        },

        // 유저 1뎁스 메뉴
        setArrUserMenu_d1: ({ commit, state }, param) => {
            commit("setArrUserMenu_d1", param);
        },

        // 유저 2뎁스 메뉴
        setArrUserMenu_d2: ({ commit, state }, param) => {
            commit("setArrUserMenu_d2", param);
        },

    },

    // module
    modules: {
    },

});
