export default {
    install(Vue) {
        //------------------------------------------[[ function ]]-------------------------------------------

        // 로그 출력
        Vue.prototype.$PrintLog = function (_log) {
            console.log(_log);
            return true;
        };

        // 페이지 이동
        Vue.prototype.$PageMove = function (_url) {

            // dimm 보임 처리
            this.$ShowDimmLogo(() => {

                var max = 99;
                var min = 10
                var ran = Math.floor(Math.random() * (max - min) + min);

                var arrURL = _url.split("?");

                var arrQS = [];
                if (arrURL.length > 1) {
                    arrQS = arrURL[1].split("&");
                }

                var url = arrURL[0] + "?";

                var sharp = '';
                for (var idx = 0; idx < arrQS.length; idx++) {
                    if (idx > 0) {
                        url += "&";
                    }

                    var _arrQS = arrQS[idx].split("#");
                    sharp = _arrQS[1];
                    url += _arrQS[0];
                }
                url += "&rd=" + ran;

                if (sharp) {
                    url += "#" + sharp;
                }

                url = url.replace("?&", "?");

                // alert(url)

                // 이동
                this.$router.push(url).catch(() => {
                    this.$HideDimmLogo();
                });

            });

            return true;
        };

        // dimm 보임 처리
        Vue.prototype.$ShowDimmLogo = function (_fnc) {
            let state = $("#dimm_logo").css("display");
            if ("none" == state) {
                if (_fnc) {
                    $("#dimm_logo").fadeIn(300, _fnc);
                } else {
                    $("#dimm_logo").fadeIn(300, function () { });
                }
            }
            return true;
        };

        // dimm 숨김 처리
        Vue.prototype.$HideDimmLogo = function (_fnc) {
            let state = $("#dimm_logo").css("display");
            if ("block" == state) {
                if (_fnc) {
                    $("#dimm_logo").fadeOut(500, _fnc);
                } else {
                    $("#dimm_logo").fadeOut(500, function () { });
                }
            }

            return true;
        };

        // 엔터
        Vue.prototype.$SetEventEnter = function (_name, _fnc) {

            $("[name='" + _name + "']").on("keydown", function (e) {

                if (e.keyCode === 13) {

                    e.preventDefault();

                    if (_fnc) {
                        _fnc();
                    }
                }
            });

            return true;
        };

        // AfterMounted
        Vue.prototype.$AfterMounted = function (Vue) {

            // 페이징 function 세팅
            Vue.getRowNum = function (idx) {
                return Vue.rownum - idx;
            };

            // form 안의 enter 키 클릭시 검색
            $(document).off("keydown", "[name='search_text']");
            $(document).on("keydown", "[name='search_text']", function (e) {

                if (e.keyCode === 13) {

                    e.preventDefault();
                    Vue.getSearchData(1);
                }
            });

            //옵션 체크시 자동 검색
            $(document).off("click", ".dev_option :checkbox");
            $(document).on("click", ".dev_option :checkbox", function () {

                Vue.getSearchData(1);

            });

            // 검색버튼 클릭시
            $(document).off("click", ".dev_btn_search");
            $(document).on("click", ".dev_btn_search", function () {

                Vue.getSearchData(1);

            });

            // 저장버튼 클릭시
            $(document).off("click", ".dev_btn_submit");
            $(document).on("click", ".dev_btn_submit", function () {

                Vue.saveData();

            });

            // 삭제버튼 클릭시
            $(document).off("click", ".dev_btn_delete");
            $(document).on("click", ".dev_btn_delete", function () {

                Vue.deleteData();

            });

            // 첫 데이터 조회
            Vue.init(Vue);

        };

        // AfterMounted
        Vue.prototype.$AfterUpdated = function (Vue) {

            // 첨부파일 확장자에 맞게 이미지 변경
            changeFileItemImg();

        };

        // 쿼리스트링
        Vue.prototype.$GetQueryMap = function () {

            var params = {};
            window.location.search.replace(
                /[?&]+([^=&]+)=([^&]*)/gi,
                function (str, key, value) {
                    params[key] = decodeURI(value);
                }
            );
            return params;

        };

        // form to map 변환
        Vue.prototype.$FormToMap = function ($form) {

            var form = {}

            var $arrEle = [];

            // text
            $arrEle = $.merge($arrEle, $form.find("[type=text]"));

            // hidden
            $arrEle = $.merge($arrEle, $form.find("[type=hidden]"));

            // password
            $arrEle = $.merge($arrEle, $form.find("[type=password]"));

            // radio
            $arrEle = $.merge($arrEle, $form.find("[type=radio]:checked"));

            // checkbox
            $arrEle = $.merge($arrEle, $form.find("[type=checkbox]:checked"));

            // textarea
            $arrEle = $.merge($arrEle, $form.find("textarea"));

            // select
            $arrEle = $.merge($arrEle, $form.find("select"));

            // name 별 value 정리
            $.each($arrEle, function (idx, el) {

                var name = el.name;
                var value = el.value;

                var arr = form[name];
                if (!arr) {                    
                    arr = form[name] = [];
                }

                arr.push(value);
            });

            var arrKey = Object.keys(form);
            for (var idx = 0; idx < arrKey.length; idx++) {

                var arrVal = form[arrKey[idx]];
                form[arrKey[idx]] = arrVal.join(",")
            }

            return form;

            //return $form.serializeObject();
        };

        //------------------------------------------[[ ajax ]]-------------------------------------------

        // Get
        Vue.prototype.$SendGet = function (_url, _func) {
            /*
            _url  : string
            _func : function(data, err)
            */

            $.ajax({
                url: _url,
                async: false, // false 일 경우 동기 요청으로 변경
                type: "GET",
                contentType: "application/x-www-form-urlencoded; charset=UTF-8", // 보내는 데이터 타입
                dataType: "text", // 받는 데이터 타입
                success: (json) => {

                    let _ob = this.$FromJson(json);
                    let data = this.$FromJson(this.$DecBase64(_ob.DATA));

                    // 에러
                    if (false == this.$IsEmpty(data.RESULT_EXCEPTION)) {
                        console.log(data.RESULT_EXCEPTION);
                    }

                    // 알림
                    if (false == this.$IsEmpty(data.RESULT_ALERT)) {
                        alert(data.RESULT_ALERT);
                    }

                    // 기능
                    if (false == this.$IsEmpty(data.RESULT_FUNC)) {
                        eval(data.RESULT_FUNC);
                    }

                    if (false == this.$IsEmpty(data.RESULT_URL)) {
                        // 이동
                        this.$router.push(data.RESULT_URL).catch(() => {
                            this.$HideDimmLogo();
                        });
                    } else {
                        // 리턴
                        _func(data, false);
                    }
                },
                error: (jqXHR, exception) => {
                    let msg = "";
                    if (jqXHR.status === 0) {
                        msg = "Not connect.\n Verify Network.";
                    } else if (jqXHR.status === 404) {
                        msg = "Requested page not found. [404]";
                    } else if (jqXHR.status === 500) {
                        msg = "Internal Server Error [500].";
                    } else if (exception === "parsererror") {
                        msg = "Requested JSON parse failed.";
                    } else if (exception === "timeout") {
                        msg = "Time out error.";
                    } else if (exception === "abort") {
                        msg = "Ajax request aborted.";
                    } else {
                        msg = "Uncaught Error.\n" + jqXHR.responseText;
                    }

                    console.log("Err : ");
                    console.log(" > " + msg);

                    // 리턴
                    _func(false, msg);
                },
            });

            return true;
        };

        // Post
        Vue.prototype.$SendPost = function (_url, _data, _func) {
            /*
            _url  : string
            _data : map
            _func : function(data, err)
            */

            $.ajax({
                url: _url,
                async: false, // false 일 경우 동기 요청으로 변경
                type: "POST",
                data: _data,
                contentType: "application/x-www-form-urlencoded; charset=UTF-8", // 보내는 데이터 타입
                dataType: "text", // 받는 데이터 타입
                success: (json) => {

                    let _ob = this.$FromJson(json);
                    let data = this.$FromJson(this.$DecBase64(_ob.DATA));

                    // 에러
                    if (false == this.$IsEmpty(data.RESULT_EXCEPTION)) {
                        console.log(data.RESULT_EXCEPTION);
                    }

                    // 알림
                    if (false == this.$IsEmpty(data.RESULT_ALERT)) {
                        alert(data.RESULT_ALERT);
                    }

                    // 기능
                    if (false == this.$IsEmpty(data.RESULT_FUNC)) {
                        eval(data.RESULT_FUNC);
                    }

                    if (false == this.$IsEmpty(data.RESULT_URL)) {
                        // 이동
                        this.$router.push(data.RESULT_URL).catch(() => {
                            this.$HideDimmLogo();
                        });
                    } else {
                        // 리턴
                        _func(data, false);
                    }
                },
                error: (jqXHR, exception) => {
                    let msg = "";
                    if (jqXHR.status === 0) {
                        msg = "Not connect.\n Verify Network.";
                    } else if (jqXHR.status === 404) {
                        msg = "Requested page not found. [404]";
                    } else if (jqXHR.status === 500) {
                        msg = "Internal Server Error [500].";
                    } else if (exception === "parsererror") {
                        msg = "Requested JSON parse failed.";
                    } else if (exception === "timeout") {
                        msg = "Time out error.";
                    } else if (exception === "abort") {
                        msg = "Ajax request aborted.";
                    } else {
                        msg = "Uncaught Error.\n" + jqXHR.responseText;
                    }

                    // 리턴
                    _func(false, msg);
                },
            });

            return true;
        };

        //------------------------------------------[[ conversion ]]-------------------------------------------

        // Empty
        Vue.prototype.$IsEmpty = function (_text_1) {
            if (_text_1 == null || typeof _text_1 == "undefined") {
                return true;
            }
            return _text_1.toString().replace(/\s/g, "").length < 1;
        };

        // IsContains
        Vue.prototype.$IsContains = function (_text_1, _text_2) {
            if (false == this.$IsEmpty(_text_1)
                && _text_1.indexOf(this.$ConString(_text_2)) > -1) {

                return true;
            }
            return false;
        };

        // IsStartWith
        Vue.prototype.$IsStartWith = function (_text_1, _text) {
            return _text_1.startsWith(_text);
        };

        // ConString
        Vue.prototype.$ConString = function (_text_1, _text_2) {
            if (this.$IsEmpty(_text_1)) {
                _text_1 = "";
            }

            if (this.$IsEmpty(_text_2)) {
                _text_2 = "";
            }

            if (!_text_1) {
                _text_1 = _text_2;
            }

            return _text_1.toString().trim();
        };

        // ConMoney
        Vue.prototype.$ConMoney = function (_text_1) {
            return this.$ConInt(_text_1).toLocaleString("ko-KR");
        };

        // ConInt
        Vue.prototype.$ConInt = function (_text_1) {
            let num = parseInt(this.$ConString(_text_1));
            if (isNaN(num)) {
                return 0;
            }
            return num;
        };

        // ConDouble
        Vue.prototype.$ConDouble = function (_text_1) {
            let num = parseFloat(this.$ConString(_text_1));
            if (isNaN(num)) {
                return 0;
            }
            return num;
        };

        // ConYN
        Vue.prototype.$ConYN = function (_text_1) {
            if (_text_1 == "Y" || _text_1 == 1) {
                return "예";
            } else if (_text_1 == "N" || _text_1 == 0) {
                return "아니오";
            } else {
                return "";
            }
        };

        // ConGender
        Vue.prototype.$ConGender = function (_text_1) {
            if (
                _text_1 == "M" ||
                _text_1 == 1 ||
                _text_1 == 3 ||
                _text_1 == 5 ||
                _text_1 == 7 ||
                _text_1 == 9
            ) {
                return "남";
            } else if (
                _text_1 == "F" ||
                _text_1 == 0 ||
                _text_1 == 2 ||
                _text_1 == 4 ||
                _text_1 == 6 ||
                _text_1 == 8
            ) {
                return "여";
            } else {
                return "";
            }
        };

        // ConHtml
        Vue.prototype.$ConHtml = function (_text_1) {
            let _textarea = document.createElement("textarea");
            if (_text_1) {
                _textarea.innerHTML = _text_1;
            }
            return _textarea.childNodes.length === 0
                ? ""
                : _textarea.childNodes[0].nodeValue;
        };

        // ToJson
        Vue.prototype.$ToJson = function (_ob_1) {
            return JSON.stringify(_ob_1);
        };

        // FromJson
        Vue.prototype.$FromJson = function (_text_1) {
            return JSON.parse(_text_1);
        };

        // EncBase64
        Vue.prototype.$EncBase64 = function (_text_1) {
            return Base64.encode(_text_1);
        };

        // DecBase64
        Vue.prototype.$DecBase64 = function (_text_1) {
            return Base64.decode(_text_1);
        };

    },
};
