<template>

</template>

<script>

    export default {
        name: ''

        , components: {
        }

        , data() {
            return {

                gubun_page: "REGIFORM"

            }
        }

        , methods: {

            // 진입점
            init() {

                // 로그아웃
                this.setLogout();

            }

            // 데이터 조회
            , setLogout() {

                var data = this.$GetQueryMap();
                data.gubun_page = this.gubun_page;

                // API 조회
                this.$SendPost("/vue_api/user/login/logout", data, this.getReturnView);

            }

            // 뷰 데이터
            , getReturnView(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>