<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <form id="regiForm" onsubmit="return false;">

            <!--전달값-->
            <input type="hidden" name="no" v-model="view_1.NO" />
            <!--//전달값-->

            <div class="pu_board">
                <div class="pu_row">
                    <div class="pu_pannel">

                        <!-- contents -->

                        <div class="pu_row">
                            <div class="pu_pannel">

                                <table class="pu_table_view">
                                    <colgroup>
                                        <col width="15%">
                                        <col width="35%">
                                        <col width="15%">
                                        <col width="35%">
                                    </colgroup>
                                    <tbody>

                                        <tr>
                                            <th>내용</th>
                                            <td colspan="3">

                                                <textarea name="contents" class="pu_input_textarea dev_input_must" v-html="view_1.CONTENTS"></textarea>

                                                <!--이미지 컨트롤러-->
                                                <div id="dev_ck_pic_wrapper"></div>
                                                <!--//이미지 컨트롤러-->

                                            </td>
                                        </tr>

                                        <!--<tr>
                                            <th>
                                                <a class="pu_btn pu_btn_add" href="javascript:;" @click="addFiles">
                                                    <i class="fas fa-paperclip"></i> 파일추가
                                                </a>

                                                <br /><br />
                                                대표사진<br />
                                                개수 : {{ cnt_lim_pic }}개<br />
                                                확장자 : jpg, jpeg, png
                                            </th>
                                            <td colspan="3">

                                                <ul id="file_item_list" class="pu_file_item_ul"
                                                    v-html="$ConHtml(view_1.HTML_FILE_ITEM_UPLOAD)"></ul>
                                                
                                            </td>
                                        </tr>-->

                                        <tr>
                                            <th>공개여부</th>
                                            <td colspan="3">
                                                <label class="pu_input_radio_label">
                                                    <input type="radio" name="show_yn"
                                                           class="pu_input_radio dev_input_must" value="Y" v-model="view_1.SHOW_YN" checked="checked" /> 공개
                                                </label>
                                                <label class="pu_input_radio_label">
                                                    <input type="radio" name="show_yn"
                                                           class="pu_input_radio dev_input_must" value="N" v-model="view_1.SHOW_YN" /> 비공개
                                                </label>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="pu_row">
                            <div class="pu_pannel">
                                <div class="col-xs-2 col-md-1">
                                    <a class="pu_btn pu_btn_list" href="javascript:;" v-on:click="$PageMove('./list')">
                                        <i class="fas fa-list-ul"></i>목록
                                    </a>
                                </div>
                                <div class="col-xs-2 col-md-1">
                                    <a class="dev_btn_delete pu_btn pu_btn_del" v-if="view_1.NO > 0" href="javascript:;">
                                        <i class="far fa-trash-alt"></i>삭제
                                    </a>
                                </div>
                                <div class="col-xs-6 col-md-9"></div>
                                <div class="col-xs-2 col-md-1">
                                    <a class="dev_btn_submit pu_btn pu_btn_regi" href="javascript:;">
                                        <i class="fas fa-edit"></i>저장
                                    </a>
                                </div>
                            </div>
                        </div>

                        <!-- //contents -->

                    </div>
                </div>
            </div>

        </form>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import

    // export
    export default {

        data() {
            return {

                gubun_page: "REGIFORM"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

                , cnt_lim_pic: 30
            }
        }

        , methods: {

            // 진입점
            init() {

                // 데이터 조회
                this.getViewData();
            }

            // 데이터 조회
            , getViewData() {

                var qs = this.$GetQueryMap();

                var data = {};
                data.gubun_page = this.gubun_page;
                data.no = qs.no;
                data.type = "B";

                // API 조회
                this.$SendPost("/vue_api/user/ideals/view", data, this.viewDataReturn);
            }

            // 데이터 조회 결과
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰

                        // 이후작업
                        {
                            // 보임여부 기본값
                            if (!this.view_1.SHOW_YN) {
                                this.view_1.SHOW_YN = "Y";
                            }
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }

                    // ck editor 세팅
                    this.setCkEditor();
                }
            }

            // 저장
            , saveData() {

                // 유호성
                {
                    // 내용
                    CKEDITOR.instances.contents.updateElement();
                    var contents = $("[name='contents']");
                    if (!contents.val().trim()) {
                        alert("내용을 입력해주세요");
                        contents.focus();
                        return false;
                    }

                    // 공개여부
                    var show_yn = $("[name='show_yn']:checked");
                    if (show_yn.length < 1) {
                        alert("공개여부를 선택해주세요");
                        $($("[name='show_yn']")[0]).focus();
                        return false;
                    }
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($("#regiForm"));
                    data.gubun_page = this.gubun_page;
                    data.type = "B";

                    // API 조회
                    this.$SendPost("/vue_api/user/ideals/save", data, this.saveEventReturn);
                }
            }

            // 저장 결과
            , saveEventReturn(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // 삭제
            , deleteData() {

                if (confirm(INFO_CONF_DELETE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // 데이터
                    var data = {};
                    data.gubun_page = this.gubun_page;
                    data.no = this.view_1.NO;
                    data.type = "B";

                    // API 조회
                    this.$SendPost("/vue_api/user/ideals/del", data, this.deleteDataReturn);
                }
            }

            // 삭제 결과
            , deleteDataReturn(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor() {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "ideals", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }

            // 파일 추가
            , addFiles() {
                AddFiles("ideals", this.cnt_lim_pic, ".txt,.jpeg,.png");
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>