<template>

    <div class="pu_wrapper">

        <!--================ inc head top =================-->
        <inc_user_head />
        <inc_user_top />
        <!--================ //inc head top =================-->

        <div class="pu_board">
            <div class="pu_row">
                <div class="pu_pannel">

                    <!-- contents -->

                    <div class="pu_row" v-if="getUserNo == '1'">
                        <div class="pu_pannel">
                            <a class="pu_btn pu_btn_regi" href="javascript:;" v-on:click="$PageMove('./regi')" style="margin-right: 3%; max-width: 100px; float: right;">
                                <i class="fas fa-edit"></i>새글
                            </a>
                        </div>
                    </div>

                    <div class="pu_row" v-for="(row, idx) in list_1">
                        <div class="pu_pannel">

                            <div class="pu_div_blog">

                                <div v-html="$ConHtml(row.CONTENTS)"></div>

                                <div class="pu_pannel" v-if="getUserNo == '1'">
                                    <a class="pu_btn pu_btn_edit" href="javascript:;" v-on:click="$PageMove('./regi?no=' + row.NO)" style="max-width: 100px; float: right;">
                                        <i class="fas fa-edit"></i>수정
                                    </a>

                                    <div class="pu_btn" href="javascript:;" v-if="row.SHOW_YN === 'N'" style="margin-right: 5px; max-width: 100px; background-color: #cccccc; color: #ffffff; float: right;">
                                        비공개
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <!-- //contents -->

                </div>
            </div>
        </div>

        <!--================ inc foot =================-->
        <inc_user_foot />
        <!--================ //inc foot =================-->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getUserNo: "getUserNo"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , list_1: []
                , list_2: []
                , list_3: []

                , view_1: {}
                , view_2: {}
                , view_3: {}

            }
        }

        , methods: {

            // 진입점
            init() {

                this.getViewData();

            }

            // 데이터 조회
            , getViewData() {

                var data = {};
                data.gubun_page = this.gubun_page;
                data.type = "A";

                // API 조회
                this.$SendPost("/vue_api/user/ideals/list", data, this.viewDataReturn);

            }

            // 데이터 리턴
            , viewDataReturn(data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 뷰
                        this.list_1 = data.LIST_1; // 리스트

                        /*
                        // 데이터 체크
                        if ((this.gubun_page === "VIEW") && this.$IsEmpty(this.view_1.NO)) {

                            alert(INFO_FAIL_ACCESS);
                            window.history.back();

                        } else {



                        }
                        */

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>